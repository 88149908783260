import 'firebase/auth';

import axios from 'axios';
import firebase from 'firebase/app';

const BASE_URL =
  process.env.REACT_APP_ENVIRONMENT == 'development'
    ? 'https://us-central1-uout-app-dev.cloudfunctions.net/api/v1'
    : 'https://us-central1-goout-app.cloudfunctions.net/api/v1';
const AdminApi = axios.create({ baseURL: BASE_URL });

const addTokenInterceptors = () => {
  AdminApi.interceptors.request.use(async function (config) {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const token = await user.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (error) {
      return config;
    }
  });
};
addTokenInterceptors();

export default AdminApi;
