import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import Api from '../../utils/Api';
import BusinessesToolbar from './BusinessesToolbar';

type Business = {
  id: string;
  name: string;
  address: string;
};

type Props = RouteComponentProps<any>;

const Businesses = ({ history }: Props): React.ReactElement => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState<Business[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    (async function doInit() {
      const response = await Api.get('/business');
      setBusinesses(response.data);
      setFilteredBusinesses(response.data);
    })();
  }, []);

  const handleLimitChange = (event: any) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const searchBusinessesByName = (text: string) => {
    const filtered = businesses.filter((business) => {
      return business.name.toLowerCase().includes(text.toLowerCase());
    });
    setFilteredBusinesses(filtered);
  };

  return (
    <Container>
      <BusinessesToolbar doSearch={searchBusinessesByName} />
      <Card>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Registration date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBusinesses.slice(page * limit, page * limit + limit).map((business: Business) => (
                <TableRow hover key={business.id} onClick={() => history.push(`/business/${business.id}`)}>
                  <TableCell>
                    <Box>
                      <Typography color="textPrimary" variant="body1">
                        {business.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{business.address}</TableCell>
                  <TableCell>TBD</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={3}
                  count={filteredBusinesses.length}
                  rowsPerPage={limit}
                  page={page}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleLimitChange}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </Card>
    </Container>
  );
};

export default Businesses;
