import React, { HTMLAttributes } from 'react';
import MaskedInput from 'react-text-mask';

type Props = HTMLAttributes<any>;

const PhoneNumberField = ({ ...rest }: Props): React.ReactElement => {
  return (
    <MaskedInput
      {...rest}
      mask={[/[1-9]/, '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
};

export default PhoneNumberField;
