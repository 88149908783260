import {
  Button,
  Dialog as MdDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';

type Props = {
  open: boolean;
  handleClose: () => void;
  title: string;
  contentText: string;
  onApproveText: string;
  onApprove: (...args: any[]) => void;
};

const Dialog = ({ open, handleClose, onApprove, onApproveText, title, contentText }: Props): React.ReactElement => {
  const [error, setError] = useState<string>('');

  return (
    <MdDialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {error.length > 0 && <Alert severity="error">Oops, an error happened: {error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            try {
              onApprove();
            } catch (error) {
              setError(error.message);
            }
          }}
          color="primary"
          data-cy={'accept-button'}
        >
          {onApproveText}
        </Button>
      </DialogActions>
    </MdDialog>
  );
};

export default Dialog;
