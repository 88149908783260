import React from 'react';

import { Dialog } from '../../shared';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  remove: () => void;
};

const DeleteBusinessModal = ({ remove, open, setOpen }: Props): React.ReactElement => {
  return (
    <Dialog
      contentText={'Are you sure you want to remove this business? This action cannot be reverted.'}
      handleClose={() => {
        setOpen(false);
      }}
      onApprove={async () => {
        await remove();
        setOpen(false);
      }}
      onApproveText={"Yes, I'm sure"}
      open={open}
      title={'Delete Business'}
    />
  );
};

export default DeleteBusinessModal;
