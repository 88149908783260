import React from 'react';
import { Route } from 'react-router';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
const RouteWithLayout = ({ layout: Layout, component: Component, title, ...otherProps }: any) => {
  return (
    <Route
      {...otherProps}
      render={(matchProps) => (
        <Layout title={title}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
