import 'firebase/analytics';

import { CircularProgress, ThemeProvider } from '@material-ui/core';
import firebase from 'firebase/app';
import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router';

import Routes from './Routes';
import { Dispatch, RootState } from './store';
import theme from './theme';

const history = createBrowserHistory();
// eslint-disable-next-line @typescript-eslint/no-var-requires
const firebaseConfig = require(`./firebase.${process.env.REACT_APP_ENVIRONMENT}.json`);

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);
firebase.analytics();

const App = (): React.ReactElement => {
  const dispatch = useDispatch<Dispatch>();
  const { isInitialized } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    (async function doInit() {
      await dispatch.user.init();
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>{isInitialized ? <Routes /> : <CircularProgress />}</Router>
    </ThemeProvider>
  );
};

export default App;
