import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';

import { Link, LinkFields } from './Business';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(2),
  },
  roleSelect: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
}));

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  add: ({ field, value }: { field: LinkFields; value: string }) => Promise<void>;
  addableLinks: Link[];
};

const AddLinkModal = ({ add, open, setOpen, addableLinks }: Props): React.ReactElement => {
  const classes = useStyles();

  const [link, setLink] = useState<string>('');
  const [type, setType] = useState<LinkFields | undefined>(undefined);

  const handleLinkChange = (event: any) => {
    event.persist();
    setLink(event.target.value);
  };

  const handleTypeChange = (event: any) => {
    event.persist();
    setType(event.target.value);
  };

  const handleAdd = async () => {
    if (!type) {
      return;
    }

    await add({ field: type, value: link });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setLink('');
        setType(undefined);
        setOpen(false);
      }}
      className={classes.root}
    >
      <DialogTitle>Add Link</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the link and the link type.</DialogContentText>
        <TextField
          onChange={handleLinkChange}
          id="link"
          name="link"
          required
          label="Link"
          margin="normal"
          variant="outlined"
          value={link}
          autoFocus={true}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <FormControl className={classes.roleSelect} variant="outlined">
          <InputLabel>Type</InputLabel>
          <Select name="type" value={type} onChange={handleTypeChange} fullWidth label="Type">
            {addableLinks.map((link) => (
              <MenuItem value={link.field}>{link.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <DialogActions>
          <Button onClick={handleAdd} disabled={!link || !type} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddLinkModal;
