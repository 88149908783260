import { Avatar, Button, Fab, IconButton, Snackbar, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { TitledTable } from '../../shared';
import PhoneNumberField from '../../shared/PhoneNumberField';
import Api from '../../utils/Api';
import AddLinkModal from './AddLinkModal';
import AddStaffModel from './AddStaffModal';
import BusinessTypesMenu from './BusinessTypesMenu';
import DeleteBusinessModal from './DeleteBusinessModal';
import DeleteStaffModal from './DeleteStaffModal';
import { getEditableValue } from './getEditableValue';
import NoteModal from './NoteModal';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 150,
    height: 150,
  },
  businessImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

type StaffList = Staff[];

type Staff = {
  userId: string;
  name: string;
  phoneNumber: string;
  role: 'member' | 'owner';
};

type Business = {
  id: string;
  name: string;
  address: string;
  phoneNumber?: string;
  profileImage?: string;
  email?: string;
  foodMenu?: string;
  drinkMenu?: string;
  website?: string;
  shortBio?: string;
  longBio?: string;
  reservationLink?: string;
  businessTypes: BusinessTypes;
};

type BusinessType = {
  id: string;
  text: string;
  emoji: string;
};

type BusinessTypes = BusinessType[];

type Users = User[];

type User = {
  id: string;
  name: string;
  phoneNumber: string;
};

type Note = {
  content: string;
};

export type LinkFields = 'foodMenu' | 'drinkMenu' | 'website' | 'reservationLink';

export type Link = {
  name: string;
  field: LinkFields;
  value?: string;
};

type MatchParams = {
  businessId: string;
};

type ApiNotification = {
  message: string;
  type: 'success' | 'error';
};

type Props = RouteComponentProps<MatchParams>;

const Business = ({ history, match }: Props): React.ReactElement => {
  const classes = useStyles();

  const [business, setBusiness] = useState<Business>({ id: '', name: '', address: '', businessTypes: [] });
  const [staff, setStaff] = useState<StaffList>([]);
  const [businessTypes, setBusinessTypes] = useState<BusinessTypes>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [links, setLinks] = useState<Link[]>([]);

  const [isEditingPhone, setIsEditingPhone] = useState<boolean>(false);
  const [isEditingMail, setIsEditionMail] = useState<boolean>(false);
  const [isEditingShortBio, setIsEditingShortBio] = useState<boolean>(false);
  const [isEditingLongBio, setIsEditingLongBio] = useState<boolean>(false);
  const [editedPhone, setEditedPhone] = useState<string>('');
  const [editedMail, setEditedMail] = useState<string>('');
  const [editedShortBio, setEditedShortBio] = useState<string>('');
  const [editedLongBio, setEditedLongBio] = useState<string>('');
  const [apiNotification, setApiNotification] = useState<ApiNotification | undefined>();

  const [openAddStaffModal, setOpenAddStaffModal] = useState<boolean>(false);
  const [openDeleteBusinessModal, setOpenDeleteBusinessModal] = useState<boolean>(false);
  const [openRemoveStaffModal, setOpenRemoveStaffModal] = useState<boolean>(false);
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);
  const [openLinkModal, setOpenLinkModal] = useState<boolean>(false);

  const [staffToRemove, setStaffToRemove] = useState<string>('');

  useEffect(() => {
    (async function doInitBusiness() {
      try {
        const response = await Api.get(`/business/${match.params.businessId}`);
        setBusiness(response.data);
      } catch (error) {
        history.push('/business');
      }
    })();
  }, [match.params.businessId]);

  useEffect(() => {
    setLinks([
      { name: 'Food menu', field: 'foodMenu', value: business.foodMenu },
      { name: 'Drink menu', field: 'drinkMenu', value: business.drinkMenu },
      { name: 'Website', field: 'website', value: business.website },
      { name: 'Reservation', field: 'reservationLink', value: business.reservationLink },
    ]);
  }, [business]);

  useEffect(() => {
    (async function doInitStaff() {
      try {
        const response = await Api.get(`/business/${match.params.businessId}/staff`);
        setStaff(response.data);
      } catch (error) {
        history.push('/business');
      }
    })();
  }, [match.params.businessId]);

  useEffect(() => {
    (async function doInitBusinessTypes() {
      try {
        const response = await Api.get(`/business_types`);
        setBusinessTypes(response.data);
      } catch (error) {
        setBusinessTypes([]);
      }
    })();
  }, []);

  useEffect(() => {
    (async function doInitBusinessNotes() {
      try {
        const response = await Api.get(`/business/${match.params.businessId}/notes`);
        setNotes(response.data);
      } catch (error) {
        setNotes([]);
      }
    })();
  }, [match.params.businessId]);

  const addStaff = async ({ shareableId, role }: { shareableId: string; role: string }) => {
    const usersResponse = await Api.get(`/users?shareableId=${shareableId}`);
    const users: Users = usersResponse.data;
    if (users.length === 0) {
      throw new Error(`No users found with the identifier: ${shareableId}`);
    }
    if (users.length > 1) {
      throw new Error(
        `We found more than one users with the identifier ${shareableId}. Please contact a system administrator.`,
      );
    }
    await Api.post(`/business/${match.params.businessId}/staff`, { userId: users[0].id, role });
    const response = await Api.get(`/business/${match.params.businessId}/staff`);
    setStaff(response.data);
  };

  const removeStaff = async (userId: string) => {
    await Api.delete(`/business/${match.params.businessId}/staff/${userId}`);
    const response = await Api.get(`/business/${match.params.businessId}/staff`);
    setStaff(response.data);
  };

  const deleteBusiness = async () => {
    await Api.delete(`/business/${match.params.businessId}`);
    history.push('/business');
  };

  const addNote = async (note: string) => {
    await Api.post(`/business/${match.params.businessId}/notes`, { content: note });
    const response = await Api.get(`/business/${match.params.businessId}/notes`);
    setNotes(response.data);
  };

  const selectType = async (id: string) => {
    await Api.put(`/business/${match.params.businessId}/types`, { businessTypeIds: [id] });
    const response = await Api.get(`/business/${match.params.businessId}`);
    setBusiness(response.data);
  };

  const currentType = (): string => {
    return business.businessTypes.length === 1 ? business.businessTypes[0].id : '';
  };

  const saveNewPhone = async () => {
    const cleanPhoneNumber = editedPhone.replace(/\D/g, '');
    try {
      await Api.patch(`/business/${match.params.businessId}`, { phoneNumber: `+${cleanPhoneNumber}` });
      setBusiness({ ...business, phoneNumber: `+${cleanPhoneNumber}` });
      setIsEditingPhone(false);
      setApiNotification({ message: 'Phone number was successfully updated.', type: 'success' });
    } catch (error) {
      setApiNotification({ message: error.response.data.error, type: 'error' });
    }
  };

  const save = async ({
    field,
    value,
    setIsEditing,
  }: {
    field: string;
    value: string;
    setIsEditing: (isEditing: boolean) => void;
  }) => {
    try {
      await Api.patch(`/business/${match.params.businessId}`, { [field]: value });
      setBusiness({ ...business, [field]: value });
      setIsEditing(false);
      setApiNotification({ message: `${field} was successfully updated.`, type: 'success' });
    } catch (error) {
      setApiNotification({ message: error.response.data.error, type: 'error' });
    }
  };

  const deleteLink = async (link: Link) => {
    try {
      await Api.patch(`/business/${match.params.businessId}`, { [link.field]: '' });
      setBusiness({ ...business, [link.field]: '' });
      setApiNotification({ message: `Link ${link.field} was successfully deleted.`, type: 'success' });
    } catch (error) {
      setApiNotification({ message: error.response.data.error, type: 'error' });
    }
  };

  const addLink = async ({ field, value }: { field: LinkFields; value: string }) => {
    try {
      await Api.patch(`/business/${match.params.businessId}`, { [field]: value });
      setBusiness({ ...business, [field]: value });
      setApiNotification({ message: `Link ${field} was successfully added.`, type: 'success' });
    } catch (error) {
      setApiNotification({ message: error.response.data.error, type: 'error' });
    }
  };

  const handleStartEditingPhone = () => {
    setEditedPhone(business.phoneNumber || '');
    setIsEditingPhone(true);
  };

  const handleStartEditingMail = () => {
    setEditedMail(business.email || '');
    setIsEditionMail(true);
  };

  const handleStartEditingShortBio = () => {
    setEditedShortBio(business.shortBio || '');
    setIsEditingShortBio(true);
  };

  const handleStartEditingLongBio = () => {
    setEditedLongBio(business.longBio || '');
    setIsEditingLongBio(true);
  };

  const handleClearEditingPhone = () => {
    setIsEditingPhone(false);
  };

  return (
    <div>
      <div className={classes.businessImage}>
        <Avatar src={business.profileImage} className={classes.avatar} variant="square" title="image">
          {business.name}
        </Avatar>
      </div>
      <TitledTable
        title={'Information'}
        rows={[
          ['Name', business.name],
          ['Address', business.address],
          getEditableValue({
            id: 'phoneNumber',
            label: 'Phone Number',
            setEditedValue: setEditedPhone,
            isEditing: isEditingPhone,
            editedValue: editedPhone,
            inputComponent: PhoneNumberField,
            save: saveNewPhone,
            clear: handleClearEditingPhone,
            edit: handleStartEditingPhone,
            value: business.phoneNumber,
          }),
          getEditableValue({
            id: 'mail',
            label: 'Mail',
            setEditedValue: setEditedMail,
            isEditing: isEditingMail,
            editedValue: editedMail,
            save: () => save({ field: 'email', value: editedMail, setIsEditing: setIsEditionMail }),
            clear: () => setIsEditionMail(false),
            edit: handleStartEditingMail,
            value: business.email,
          }),
          getEditableValue({
            id: 'shortBio',
            label: 'Short Bio',
            setEditedValue: setEditedShortBio,
            isEditing: isEditingShortBio,
            editedValue: editedShortBio,
            save: () => save({ field: 'shortBio', value: editedShortBio, setIsEditing: setIsEditingShortBio }),
            clear: () => setIsEditingShortBio(false),
            edit: handleStartEditingShortBio,
            value: business.shortBio,
          }),
          getEditableValue({
            id: 'longBio',
            label: 'Long Bio',
            setEditedValue: setEditedLongBio,
            isEditing: isEditingLongBio,
            editedValue: editedLongBio,
            save: () => save({ field: 'longBio', value: editedLongBio, setIsEditing: setIsEditingLongBio }),
            clear: () => setIsEditingLongBio(false),
            edit: handleStartEditingLongBio,
            value: business.longBio,
          }),
          ['Business type', <BusinessTypesMenu value={currentType()} types={businessTypes} handleTypes={selectType} />],
        ]}
      />
      <TitledTable
        title={'Staff'}
        subtitle={staff.length <= 0 ? 'There is no staff member in this business currently.' : ''}
        rows={staff.map((member: Staff) => [
          <Avatar>T</Avatar>,
          member.name,
          member.phoneNumber,
          member.role.charAt(0).toUpperCase() + member.role.slice(1),
          <IconButton
            onClick={() => {
              setStaffToRemove(member.userId);
              setOpenRemoveStaffModal(true);
            }}
            title={'Delete'}
          >
            <Delete />
          </IconButton>,
        ])}
      />
      <TitledTable
        title={'Delete business'}
        rows={[
          [
            'Are you sure you want to completely delete this business? This will delete the staff members and any related resources.',
            <Button onClick={() => setOpenDeleteBusinessModal(true)} variant={'contained'} color={'secondary'}>
              Delete
            </Button>,
          ],
        ]}
      />
      <TitledTable
        title={'Links'}
        subtitle={links.filter((link) => link.value).length > 0 ? '' : 'There are curently no links for this business'}
        rows={links
          .filter((link) => link.value)
          .map((link) => [
            link.name,
            link.value,
            <IconButton
              onClick={() => {
                deleteLink(link);
              }}
              title={'Delete'}
            >
              <Delete />
            </IconButton>,
          ])}
        mainButton={
          links.filter((link) => !link.value).length > 0
            ? { name: 'Add', onClick: () => setOpenLinkModal(true) }
            : undefined
        }
      />
      <AddLinkModal
        open={openLinkModal}
        setOpen={(open) => setOpenLinkModal(open)}
        add={addLink}
        addableLinks={links.filter((link) => !link.value)}
      />
      <TitledTable
        title={'Notes'}
        subtitle={notes.length <= 0 ? 'There are no notes currently for this business.' : ''}
        rows={notes.map((note: Note) => [note.content])}
        mainButton={{ name: 'Write', onClick: () => setOpenNoteModal(true) }}
      />
      <AddStaffModel open={openAddStaffModal} setOpen={() => setOpenAddStaffModal(!openAddStaffModal)} add={addStaff} />
      <DeleteStaffModal
        open={openRemoveStaffModal}
        setOpen={() => {
          setStaffToRemove('');
          setOpenRemoveStaffModal(!openRemoveStaffModal);
        }}
        remove={() => removeStaff(staffToRemove)}
      />
      <DeleteBusinessModal
        open={openDeleteBusinessModal}
        setOpen={() => {
          setOpenDeleteBusinessModal(!openDeleteBusinessModal);
        }}
        remove={deleteBusiness}
      />
      <NoteModal
        open={openNoteModal}
        setOpen={() => {
          setOpenNoteModal(!openNoteModal);
        }}
        add={addNote}
      />
      <Fab
        onClick={() => setOpenAddStaffModal(true)}
        size="small"
        color="primary"
        aria-label="add"
        className={classes.fab}
      >
        <Add />
      </Fab>
      <Snackbar
        open={apiNotification !== undefined}
        autoHideDuration={6000}
        onClose={() => setApiNotification(undefined)}
      >
        <Alert onClose={() => setApiNotification(undefined)} severity={apiNotification?.type}>
          {apiNotification?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Business;
