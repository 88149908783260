import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(1),
  },
  mainButton: {
    margin: theme.spacing(1),
  },
  tableTitleCell: {
    color: 'grey',
  },
}));

type Props = {
  className?: string;
  rows: React.ReactNode[][];
  title?: string;
  subtitle?: string;
  mainButton?: { name: string; onClick: () => void };
};

const TitledTable: React.FC<Props> = ({ className, rows, title, subtitle, mainButton }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
        <Typography>{subtitle}</Typography>
      </div>
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {row.map((value, index) =>
                  index ? (
                    <TableCell align="left" key={index}>
                      {value}
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableTitleCell} key={index}>
                      {value}
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {mainButton && (
          <Button className={classes.mainButton} variant={'outlined'} color={'primary'} onClick={mainButton.onClick}>
            {mainButton.name}
          </Button>
        )}
      </TableContainer>
    </Paper>
  );
};

export default TitledTable;
