import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';

const schema = {
  shareableId: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  role: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const initialState = {
  isValid: false,
  values: {
    shareableId: '',
    role: 'owner',
  },
  touched: {
    shareableId: false,
    role: false,
  },
  errors: {
    shareableId: '',
    role: '',
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(2),
  },
  roleSelect: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
}));

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  add: ({ shareableId, role }: { shareableId: string; role: string }) => void;
};

const AddStaffModel = ({ add, open, setOpen }: Props): React.ReactElement => {
  const classes = useStyles();

  const [error, setError] = useState<string>('');
  const [formState, setFormState] = useState(initialState);
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event: any) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field: string): boolean => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return formState.errors[field] && formState.touched[field];
  };

  const handleAdd = async () => {
    try {
      await add({
        shareableId: formState.values.shareableId,
        role: formState.values.role,
      });
      setError('');
      setFormState(initialState);
      setOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setError('');
        setFormState(initialState);
        setOpen(false);
      }}
      className={classes.root}
    >
      <DialogTitle>Add Staff Member</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the identifier and the role of the new staff member you want to add to this business.
        </DialogContentText>
        <TextField
          onChange={handleChange}
          error={hasError('shareableId')}
          helperText={hasError('shareableId') ? formState.errors.shareableId[0] : null}
          id="shareableId"
          name="shareableId"
          required
          label="Identifier"
          margin="normal"
          variant="outlined"
          value={formState.values.shareableId || ''}
          autoFocus={true}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <FormControl className={classes.roleSelect} variant="outlined">
          <InputLabel>Role</InputLabel>
          <Select name="role" value={formState.values.role || ''} onChange={handleChange} fullWidth label="Role">
            <MenuItem value="owner">Owner</MenuItem>
            <MenuItem value="member">Member</MenuItem>
          </Select>
        </FormControl>
        {error.length > 0 && <Alert severity="error">Oops, an error happened: {error}</Alert>}
        <DialogActions>
          <Button onClick={handleAdd} disabled={!formState.isValid} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddStaffModel;
