import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 150,
  },
}));

type Props = {
  value: string;
  types: { id: string; text: string; emoji: string }[];
  handleTypes: (id: string) => void;
};

const BusinessTypesMenu = ({ value, types, handleTypes }: Props): React.ReactElement => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleTypes(event.target.value as string);
  };

  return (
    <FormControl variant="outlined" className={classes.input}>
      <InputLabel id="business-types-menu-label">Types</InputLabel>
      <Select
        labelId="business-types-menu"
        id="business-types-menu"
        onChange={handleChange}
        label="Types"
        value={value || ''}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {types.map(({ id, text, emoji }, index) => (
          <MenuItem key={index} value={id}>
            {emoji} - {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BusinessTypesMenu;
