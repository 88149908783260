import 'firebase/auth';

import { createModel } from '@rematch/core';
import firebase from 'firebase/app';

import { RootModel } from '../index';

export type User = {
  id: string;
};

export type UserState = {
  currentUser: User | null;
  confirmationResult: firebase.auth.ConfirmationResult | null;
  isInitialized: boolean;
};

export const user = createModel<RootModel>()({
  state: {
    currentUser: null,
    confirmationResult: null,
    isInitialized: false,
  } as UserState,
  reducers: {
    setCurrentUser(state, currentUser: User | null) {
      return { ...state, currentUser };
    },
    setConfirmationResult(state, confirmationResult: firebase.auth.ConfirmationResult | null) {
      return { ...state, confirmationResult };
    },
    setIsInitialized(state, isInitialized: boolean) {
      return { ...state, isInitialized };
    },
  },
  effects: (dispatch) => ({
    async init() {
      firebase.auth().onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
        if (firebaseUser) {
          const tokenResult = await firebaseUser.getIdTokenResult();
          if (tokenResult.claims.role !== 'admin') {
            await dispatch.user.signOut();
          } else {
            dispatch.user.setCurrentUser({ id: firebaseUser.uid });
          }
        } else {
          dispatch.user.setCurrentUser(null);
        }
        dispatch.user.setIsInitialized(true);
      });
    },
    async signInWithPhoneNumber({
      phoneNumber,
      recaptchaVerifier,
    }: {
      phoneNumber: string;
      recaptchaVerifier: firebase.auth.ApplicationVerifier;
    }) {
      const confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, recaptchaVerifier);
      dispatch.user.setConfirmationResult(confirmationResult);
    },
    async confirmSignIn({ verificationCode }: { verificationCode: string }, rootState) {
      if (rootState.user.confirmationResult) {
        const result = await rootState.user.confirmationResult.confirm(verificationCode);
        if (result.user) {
          const token = await result.user?.getIdTokenResult();
          if (token.claims.role !== 'admin') {
            throw new Error(
              'You do not have the required permission to access this content. Please contact an administrator if you think this is an error.',
            );
          }
          dispatch.user.setConfirmationResult(null);
        }
      } else {
        throw new Error('No confirmation result to be use to confirm verification code.');
      }
    },
    async signOut() {
      await firebase.auth().signOut();
    },
  }),
});
