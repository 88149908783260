import { IconButton, TextField } from '@material-ui/core';
import { Check, Clear, Edit } from '@material-ui/icons';
import React from 'react';

type Props = {
  id: string;
  label: string;
  setEditedValue: (event: any) => void;
  isEditing: boolean;
  editedValue?: string;
  inputComponent?: any;
  save: (event: any) => void;
  clear: (event: any) => void;
  edit: (event: any) => void;
  value: any;
};

export const getEditableValue = ({
  id,
  label,
  setEditedValue,
  isEditing,
  editedValue = '',
  inputComponent = undefined,
  save,
  clear,
  edit,
  value,
}: Props): any => {
  return [
    label,
    isEditing ? (
      <TextField
        onChange={(event) => setEditedValue(event.target.value)}
        id={id}
        name={id}
        label={label}
        margin="normal"
        variant="outlined"
        value={editedValue}
        InputProps={{
          inputComponent,
        }}
        InputLabelProps={{ shrink: true }}
      />
    ) : (
      value || 'Not associated.'
    ),
    <div>
      {isEditing ? (
        <div>
          <IconButton onClick={save} title={'Save'}>
            <Check />
          </IconButton>
          <IconButton onClick={clear} title={'Clear'}>
            <Clear />
          </IconButton>
        </div>
      ) : (
        <IconButton onClick={edit} title={'Edit'}>
          <Edit />
        </IconButton>
      )}
    </div>,
  ];
};
