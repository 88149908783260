import 'firebase/auth';

import { Button, Paper, TextField, Theme, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import PhoneNumberField from '../../shared/PhoneNumberField';
import { Dispatch, RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formElement: {
    margin: theme.spacing(2),
  },
}));

const Login = (): React.ReactElement => {
  const classes = useStyles();

  const { currentUser, confirmationResult } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<Dispatch>();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verificationCodeError, setVerificationCodeError] = useState<string>('');

  const handleCodeVerification = async () => {
    setVerificationCodeError('');
    try {
      await dispatch.user.confirmSignIn({ verificationCode: verificationCode });
    } catch (error) {
      setVerificationCodeError(error.message);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('login-button', {
        size: 'invisible',
      });
    }
  }, [currentUser]);

  if (currentUser) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={classes.root}>
        <Paper className={classes.formPaper}>
          <Typography>Connect</Typography>
          <TextField
            className={classes.formElement}
            id="phone-number"
            label="Phone number"
            value={phoneNumber || ''}
            onChange={(event) => setPhoneNumber(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus={true}
            InputProps={{
              inputComponent: PhoneNumberField,
            }}
          />
          <div id="recaptcha-container" />
          <Button
            id={'login-button'}
            className={classes.formElement}
            onClick={async () => {
              await dispatch.user.signInWithPhoneNumber({
                phoneNumber: `+${phoneNumber}`,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                recaptchaVerifier: window.recaptchaVerifier,
              });
            }}
          >
            Login
          </Button>

          {confirmationResult && (
            <>
              <TextField
                className={classes.formElement}
                id="confirmation-code"
                label="Confirmation Code"
                type="text"
                value={verificationCode || ''}
                onChange={(event) => setVerificationCode(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button className={classes.formElement} onClick={handleCodeVerification}>
                Validate
              </Button>
              {verificationCodeError && (
                <Alert severity="error">
                  Oops, we were unable to log you in for the following reason: {verificationCodeError}
                </Alert>
              )}
            </>
          )}
        </Paper>
      </div>
    );
  }
};

export default Login;
