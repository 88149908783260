import { Button, List, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';

import Api from '../../utils';
import { BusinessResultItem } from './components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
  },
  formElement: {
    margin: theme.spacing(1),
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
}));

export type SearchBusinessesResult = {
  id: string;
  name: string;
  address: string;
  longitude: number;
  latitude: number;
};

const SearchBusinesses = (): React.ReactElement => {
  const classes = useStyles();

  const [textSearch, setTextSearch] = useState<string>('');
  const [searchResult, setSearchResult] = useState<SearchBusinessesResult[]>([]);

  return (
    <div className={classes.root}>
      <TextField
        className={classes.formElement}
        id="search"
        label="Search businesses"
        type="text"
        value={textSearch || ''}
        onChange={(event) => setTextSearch(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        className={classes.formElement}
        id={'search-button'}
        onClick={async () => {
          const response = await Api.get('/search/nearby_business', {
            params: {
              long: 46,
              lat: 71,
              radius: 500000,
              textSearch: textSearch,
            },
          });
          const results: SearchBusinessesResult[] = response.data.map(
            ({ id, name, address, latitude, longitude }: SearchBusinessesResult) => {
              return { id, name, address, latitude, longitude };
            },
          );
          setSearchResult(results);
        }}
      >
        Search
      </Button>
      <List className={classes.list}>
        {searchResult.map(({ id, name, address }) => {
          return (
            <BusinessResultItem
              key={id}
              doImport={async () => {
                await Api.post('/business', { id });
              }}
              businessItem={{ id, name, address }}
            />
          );
        })}
      </List>
    </div>
  );
};

export default SearchBusinesses;
