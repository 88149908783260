import React from 'react';
import { Redirect, Switch } from 'react-router';

import { BUSINESS_PATH, BUSINESSES_PATH, LOGIN_PATH, SEARCH_BUSINESS_PATH } from './constants';
import { Main as MainLayout } from './layout';
import { PrivateRouteWithLayout, RouteWithLayout } from './shared';
import {
  Business as BusinessView,
  Businesses as BusinessesView,
  Login as LoginView,
  SearchBusinesses as SearchBusinessesView,
} from './views';

const Routes = (): React.ReactElement => {
  return (
    <Switch>
      <Redirect exact from="/" to={BUSINESSES_PATH} />
      <PrivateRouteWithLayout
        component={SearchBusinessesView}
        exact
        layout={MainLayout}
        title={'Search Businesses'}
        path={SEARCH_BUSINESS_PATH}
      />
      <PrivateRouteWithLayout
        component={BusinessesView}
        exact
        layout={MainLayout}
        title={'Businesses'}
        path={BUSINESSES_PATH}
      />
      <PrivateRouteWithLayout
        component={BusinessView}
        exact
        layout={MainLayout}
        title={'Business'}
        path={BUSINESS_PATH}
      />
      <RouteWithLayout component={LoginView} exact layout={MainLayout} title={'Login'} path={LOGIN_PATH} />
      <Redirect to={BUSINESSES_PATH} />
    </Switch>
  );
};

export default Routes;
