import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

import { LOGIN_PATH } from '../../constants';
import { RootState } from '../../store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRouteWithLayout = ({
  layout: Layout,
  component: Component,
  title,
  ...otherProps
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any): React.ReactElement => {
  const { currentUser } = useSelector((state: RootState) => state.user);

  if (currentUser) {
    return (
      <Route
        {...otherProps}
        render={(props) => (
          <Layout title={title}>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
  return <Redirect to={LOGIN_PATH} />;
};

export default PrivateRouteWithLayout;
