import { Box, Button, Card, CardContent, InputAdornment, SvgIcon, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { HTMLAttributes } from 'react';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type Props = { doSearch: (text: string) => void } & HTMLAttributes<any>;

const BusinessesToolbar = ({ doSearch, ...rest }: Props): React.ReactElement => {
  const history = useHistory();
  const classes = useStyles();

  const onSearchText = (event: any) => {
    doSearch(event.target.value);
  };

  return (
    <Box {...rest}>
      <Box className={classes.root}>
        <Button onClick={() => history.push(`/search/business`)} color="primary" variant="contained">
          Add Business
        </Button>
      </Box>
      <Box>
        <Card>
          <CardContent>
            <Box>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <Search />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Business"
                variant="outlined"
                onChange={onSearchText}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default BusinessesToolbar;
