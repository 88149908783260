import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Theme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';

const schema = {
  note: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const initialState = {
  isValid: false,
  values: {
    note: '',
  },
  touched: {
    note: false,
  },
  errors: {
    note: '',
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(2),
  },
  roleSelect: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
}));

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  add: (note: string) => void;
};

const NoteModal = ({ add, open, setOpen }: Props): React.ReactElement => {
  const classes = useStyles();

  const [error, setError] = useState<string>('');
  const [formState, setFormState] = useState(initialState);
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event: any) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field: string): boolean => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return formState.errors[field] && formState.touched[field];
  };

  const handleAdd = async () => {
    try {
      await add(formState.values.note);
      setError('');
      setFormState(initialState);
      setOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setError('');
        setFormState(initialState);
        setOpen(false);
      }}
      className={classes.root}
    >
      <DialogTitle>Add Note</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter a text note associate to this business.</DialogContentText>
        <TextField
          onChange={handleChange}
          error={hasError('note')}
          helperText={hasError('note') ? formState.errors.note[0] : null}
          id="note"
          name="note"
          required
          label="Note"
          margin="normal"
          variant="outlined"
          value={formState.values.note || ''}
          autoFocus={true}
          multiline
          rows={4}
          fullWidth
        />
        {error.length > 0 && <Alert severity="error">Oops, an error happened: {error}</Alert>}
        <DialogActions>
          <Button onClick={handleAdd} disabled={!formState.isValid} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default NoteModal;
