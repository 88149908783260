import { Button, Grid, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  appBarText: {
    color: theme.palette.common.white,
  },
  logo: {
    width: 48,
    margin: theme.spacing(2),
    alignSelf: 'center',
  },
  title: {
    flexGrow: 1,
  },
  content: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

type Props = {
  children: React.ReactChildren;
  title: string;
  className: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Main = ({ children, title, className, ...rest }: Props): React.ReactElement => {
  const classes = useStyles();

  const { currentUser } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<Dispatch>();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <AppBar position={'sticky'}>
        <Toolbar>
          <Grid alignItems="center" container spacing={6}>
            <Grid item>
              <img className={classes.logo} alt="Logo" src="/images/white_logo_transparent_background.png" />
            </Grid>

            <Grid item className={classes.title}>
              <Typography className={classes.appBarText} variant="h3">
                {title}
              </Typography>
            </Grid>

            <Grid item>
              <div>
                {currentUser && (
                  <Button className={classes.appBarText} onClick={() => dispatch.user.signOut()}>
                    Log out
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <main className={classes.content} {...rest}>
        {children}
      </main>
    </div>
  );
};

export default Main;
