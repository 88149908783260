import { colors } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const white = '#FFFFFF';
const black = '#000000';

export const palette: PaletteOptions = {
  common: {
    white,
    black,
  },
  primary: {
    contrastText: white,
    main: '#3F2D87',
  },
  secondary: {
    contrastText: white,
    main: '#D51317',
  },
  success: {
    contrastText: white,
    dark: colors.lime[900],
    main: colors.lime[600],
    light: colors.lime[100],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[100],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[100],
  },
  error: {
    contrastText: white,
    dark: colors.deepOrange[900],
    main: colors.deepOrange[600],
    light: colors.deepOrange[100],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  divider: colors.grey[200],
};
