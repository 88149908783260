import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline',
  },
}));

type Import = {
  isImporting: boolean;
  status: 'success' | 'failure' | 'none';
};

type BusinessItem = {
  id: string;
  name: string;
  address: string;
};

type Props = {
  businessItem: BusinessItem;
  doImport: () => void;
};

const BusinessResultItem = ({ businessItem, doImport }: Props): React.ReactElement => {
  const classes = useStyles();
  const labelId = `checkbox-list-label-${businessItem.id}`;
  const [importStatus, setImportStatus] = useState<Import>({
    isImporting: false,
    status: 'none',
  });

  const handleImport = () => {
    setImportStatus({
      isImporting: true,
      status: 'none',
    });
    try {
      doImport();
      setImportStatus({
        isImporting: false,
        status: 'success',
      });
    } catch (error) {
      setImportStatus({
        isImporting: false,
        status: 'failure',
      });
    }
  };

  const getActionIcon = () => {
    if (importStatus.status === 'success') {
      return <DoneAllIcon style={{ color: 'green' }} />;
    } else if (importStatus.status === 'failure') {
      return <ErrorIcon style={{ color: 'red' }} />;
    } else {
      return <AddIcon />;
    }
  };

  return (
    <ListItem key={businessItem.id} role={undefined}>
      <ListItemText
        id={labelId}
        primary={businessItem.name}
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
              {businessItem.address}
            </Typography>
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="import" onClick={handleImport}>
          {getActionIcon()}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default BusinessResultItem;
